import React, { Component } from "react";

class Rules extends Component {

  render() {
    return (
      <div className="content-container">
        <div className="content content-rules">
          <div className="content-rules-header">
            <h2>Regulamin</h2>
            <object data="https://bieggenerala.pl/Regulamin-2023.pdf" type="application/pdf" width="100%" height="1000">              
            </object>

           </div>
        </div>
      </div>
    );
  }
}

export default Rules;
